.Navigation-bar {
  width: 100%;
  z-index: 30;
  background-color: #fff;
}

.Navigation-divider {
  height: 1px;
  width: 100%;
  background-color: #ffebee;
}
