.Signup-bg {
  height: 100vh;
  background: linear-gradient(to bottom, #ffebee 80%, #ffffff 100%);
}

.Signup-btn-circle {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding-top: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.03),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.03),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.03);
}
