.Profile-bg::before {
  content: "";
  background-image: url("../assets/avatars-bg.png");
  background-repeat: repeat;
  margin: 0 auto;
  height: 100vh;
  max-width: 67vw;
  opacity: 0.1;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Profile-container {
  height: 100%;
  width: 100%;
}

.Profile-logo {
  height: 15vmin;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 5px 5px 15px 5px #ff8080, -9px 5px 15px 5px #ffe488,
    12px 10px 15px 7px #e488ff, -50px -50px 17px 34px rgba(0, 0, 0, 0);
}

.Profile-calendar {
  width: 100%;
  height: 900px;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .Profile-calendar {
    height: 1080px;
  }

  /* .Profile-bg::before {
    display: none;
  } */
}

.Profile-donut {
  height: 150px;
  transform: rotate(-20deg);
}

@media screen and (max-width: 768px) {
  .Profile-donut {
    height: 60px;
  }
  .Profile-logo {
    height: 25vmin;
  }
  .Profile-banner-desktop {
    position: relative;
    display: none;
  }
}

.Profile-cta {
  position: absolute;
  left: -20%;
  top: 30%;
  height: 300px;
}

.Profile-banner-desktop,
.Profile-banner-mobile {
  position: absolute;
  width: 100vw;
  background-color: #ff8080;
}

@media screen and (min-width: 768px) {
  .Profile-banner-mobile {
    position: relative;
    display: none;
  }
}
