.main {
  height: 63vh;
}

.avatars-bg {
  background-image: url("../assets/avatars-bg.png");
  background-repeat: repeat;
  height: 63vh;
  max-width: 63vw;
  /* transform: rotate(-12deg); */
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .avatars-bg {
    height: 100vh;
    top: 0;
    max-width: 100vw;
    opacity: 0.1;
  }

  .main {
    height: 100vh;
  }

  .main-intro {
    position: absolute;
  }
}
