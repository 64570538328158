.product-image {
  width: 60vw;
  max-width: 900px;
}

@media screen and (max-width: 768px) {
  .product-image {
    width: 90vw;
  }
}
